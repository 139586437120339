import React, { useState, useEffect } from 'react';
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import './earthly.css';
import Axios from 'axios';
import Qs from 'querystring';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import SelectSearch from 'react-select/creatable';

import product_img_1 from '../images/product_one.png';
import product_slider_two from '../images/product_slider_two.png';
import product_slider_three from '../images/product_slider_three.png';
import product_slider_four from '../images/product_slider_four.png';

import buy_on_amazon from '../images/buy_on_amazon.png';
import amezon_product_start from '../images/amezon_product_start.png';


import hero_img from '../images/earthlybanner_new.png';
import {API_URL,CONFIG_WITHOUT_TOKEN} from '../appconstant/index';


const WAIT_INTERVAL = 1000;

const ENTER_KEY = 13;

const Catalog = () => {
    const [timer, setTimer] = React.useState(null);
    const [changedata, setChangedata] = React.useState("");
    const [token, setToken] = React.useState("");
    const [productdata, setProductdata] = React.useState([]);
    const [defaultproduct, setDefaultproduct] = React.useState([]);
    const [currenthover, setCurrenthover] = React.useState(-2);
    const [defaultCurrenthover, setDefaultCurrenthover] = React.useState(-2);
    const [defaultdisplay, setDefaultdisplay] = React.useState("default");
    const [topprodcut,setTopproduct] = React.useState("");
    const [selectedOption , setSelectedOption] = React.useState("")
    const [allOptions,setAllOptions] = useState([]);
    const [categoryproduct ,setCategoryproduct] = useState("");

    const CONFIG_WITHOUT_TOKEN_BRAND = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    const WAIT_INTERVAL = 1000;
    const ENTER_KEY = 13;

    const getbrands = () => {
        if(categoryproduct !== ""){
            let get_brands = {
              category:categoryproduct
            }
            Axios.post(API_URL + '/apis/getcategories', Qs.stringify(get_brands),CONFIG_WITHOUT_TOKEN_BRAND)
            .then(function (response) {
                if(response.data.error == 0){
                    let all_array = [];
                    response.data.result !== "" && response.data.result.map((value,index) =>{
                        let new_obj = {};
                        new_obj.value = value.id;
                        new_obj.label = value.text;
                        all_array.push(new_obj);
                    })
                    setAllOptions(all_array);
                }

            })
            .catch(function (error) {
                console.log(error);

            });
        }
    }

    const inputChange = selectedOption => {

      if(selectedOption.length >= 3){
                clearTimeout(timer);
                let input_val = selectedOption.toLowerCase();
                setCategoryproduct(input_val);
                setTimer(setTimeout(getbrands, WAIT_INTERVAL));

            }
      };




      const handleCreate = (label) => {
            if(label !== ""){
                let set_new_options = [];
                let new_obj_options = {};
                new_obj_options.value = 1000;
                new_obj_options.label = label;
                set_new_options.push(new_obj_options);
                setAllOptions(set_new_options);
                setSelectedOption({value:"",label:label})
                let selected_category_value = {
                    name:label,
                    limit:50
                }
                Axios.post(API_URL + '/apis/findproducts', Qs.stringify(selected_category_value),CONFIG_WITHOUT_TOKEN_BRAND)
                .then(function (response) {
                    if(response.data.error == 0){
                        setProductdata(response.data.result);
                        setDefaultdisplay("search");
                    }

                })
                .catch(function (error) {
                    console.log(error);

                });

            }
        };
      const handleChange = selectedvalue => {
        setSelectedOption(selectedvalue)
        if(selectedvalue !== ""){
            let selected_category_value = {
                category:selectedvalue.label,
                limit:50
            }
            Axios.post(API_URL + '/apis/findproducts', Qs.stringify(selected_category_value),CONFIG_WITHOUT_TOKEN_BRAND)
            .then(function (response) {
                if(response.data.error == 0){
                    setProductdata(response.data.result);
                    setDefaultdisplay("search");
                }

            })
            .catch(function (error) {
                console.log(error);

            });
        }

      }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        className:"catalog"
    };
    const searchitem = (e) => {
        clearTimeout(timer);
        setChangedata(e.target.value);
        setTimer(setTimeout(triggerChange, WAIT_INTERVAL))
    }
    useEffect(()=>{
        let earthly_token = localStorage.getItem("earthly_web_token");
        setToken(earthly_token);
        default_product()
    },[])

    const default_product = () =>{
        Axios.get(API_URL + '/apis/top/products', CONFIG_WITHOUT_TOKEN)
        .then((response) => {
            if(response.data.error == 0){
                setDefaultproduct(response.data.data);
            }else if(response.data.error == 1){
                console.log();
            }
        })
        .catch((error) => {
            console.log(error);

        });
    }
    const triggerChange = () =>{
        if(changedata !== ""){
            let search_data = {
                search_product:changedata
            }
            Axios.post(API_URL + '/apis/search/products', Qs.stringify(search_data),CONFIG_WITHOUT_TOKEN)
            .then((response) => {
                if(response.data.error == 0){
                    setProductdata(response.data.result);
                    setTopproduct(response.data.top_product);
                    setDefaultdisplay("search");
                }else if(response.data.error == 1){
                }
            })
            .catch((error) => {
                console.log(error);

            });
        }else{
            setDefaultdisplay("default");
        }


    }
    const handleKeyDown = (e) =>{
        if (e.keyCode === ENTER_KEY) {
            getbrands();
        }
    }
        const checkforelement = (index) => {
            setCurrenthover(index)

    }
    const checkfordefault = (index) => {
        setDefaultCurrenthover(index)

    }
    const checkmouseleve =() =>{
        setCurrenthover(-2)
        setDefaultCurrenthover(-2)
    }

    const geticonsdata = (top_value) => {
        let fix_url = "https://earthlydev.s3-us-west-2.amazonaws.com/icons";
        let first_icon_url;
        let second_icon_url;
        if(top_value.Icons.length >= 2){
            let first_icon = top_value.Icons[0].icon;
            let icon_color = top_value.Icons[0].color.toLowerCase();
            first_icon_url = first_icon.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color + '/dots').replace('.png', ".svg");

            let second_icon = top_value.Icons[1].icon;
            let icon_color_second = top_value.Icons[1].color.toLowerCase();
            second_icon_url = second_icon.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color_second + '/dots').replace('.png', ".svg");

        }
        return(
            <>
            <li><img src={first_icon_url} width="16px" height="16px" alt="icon_img" style={{marginBottom:"36px"}}/></li>
            <li><img src={second_icon_url} width="16px" height="16px" alt="icon_img" style={{marginBottom:"36px"}}/></li>
            {top_value.Icons.length > 2 &&
                <li><label style={{marginBottom:"36px",fontSize:"13px",color:"#ffffff",position:"absolute",marginTop:"-1px"}}>+{top_value.Icons.length - 2}</label></li>
            }
            </>

        )
    }
    const getprice = (value) =>{
        let price = value.Price;
        let price_with_sign;
        let main_price;
        let decimal_price;
        if (price != "") {
            price = (price + "").split(".");
            price_with_sign = price[0];
            main_price = price_with_sign.replace("$", "");
            decimal_price = price[1] ? price[1] : "";

        }
        return (
            <p className="related_price"><span className="a-price-symbol">$</span><span className="a-price-whole">{main_price}<span className="a-price-decimal"></span></span><span className="a-price-fraction">{decimal_price}</span></p>
        )
    }
    return (
        <Layout>
        <SEO title="Catalog" image={hero_img} />
        <div className="mainsearch">

        <SelectSearch
            name="product_search"
            value ={selectedOption}
            label=""
            onKeyDown={handleKeyDown}
            options={allOptions}
            onChange={handleChange}
            onInputChange={inputChange}
            onCreateOption={handleCreate}
            placeholder="Search for item"

        />
        </div>
        <div style={{marginTop:"30px",marginBottom:"10px"}}>
        <div className="mb-4">
        {defaultdisplay == "default" &&
            defaultproduct !== "" && defaultproduct.map((defaultval,defaultindex) => {
                    return (
                        <div className="row">
                            <h2>{defaultval._id}</h2>
                            <div className="grid-container">
                            {defaultval.products !== "" && defaultval.products.map((value,index) =>{
                                let background_color = "#15B770";
                                let fix_url = "https://earthlydev.s3-us-west-2.amazonaws.com/icons";
                                let first_img_url;
                                let second_img_url;
                                if(value.Icons.length >= 2){
                                    let first_icon = value.Icons[0].icon;
                                    let icon_color = value.Icons[0].color.toLowerCase();
                                    first_img_url = first_icon.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color + '/dots').replace('.png', ".svg");

                                    let second_icon = value.Icons[1].icon;
                                    let icon_color_second = value.Icons[1].color.toLowerCase();
                                    second_img_url = second_icon.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color_second + '/dots').replace('.png', ".svg");

                                }else if(value.Icons.length == 1){
                                    let first_icon = value.Icons[0].icon;
                                    let icon_color = value.Icons[0].color.toLowerCase();
                                    first_img_url = first_icon.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color + '/dots').replace('.png', ".svg");
                                }
                                return(
                                    <div className="grid-item" key={index}>
                                    <img src={value.ImageURL !== "" ? value.ImageURL:"https://earthlydev.s3.us-west-2.amazonaws.com/ext_assets/images/placeholder.svg" } alt="" style={{height:"320px",marginBottom:"8px"}}/>
                                    <div className="name_text">
                                    <p className="product_name">{value.Amazon_Product_Full_Name}</p>
                                    </div>
                                    <div className="product_align">

                                    <div className="row earthlybtn" style={{backgroundColor:value.rating <= 2 ? "#EB5757" : "#15B770"}}  onMouseEnter={()=>checkfordefault(value.ProductID)}>
                                    {value.rating !== undefined &&
                                        <>
                                        <div className="first_leaf_rateing" style={{width:"45%"}}>
                                        <div className="leaf_star_first">
                                        <div className="star-rating harambe">
                                        <span style={{width:value.rating*20+"%"}}></span>
                                        </div>
                                        </div>
                                        </div>
                                        {value.Icons.length > 0  &&  value.Icons.length >= 3 &&
                                            <>
                                            <div className="first_icon" style={{width:"20%",borderLeft:"1px solid #ffffff"}}>
                                            <img src={first_img_url} alt="" className="icon_img_bottom" />
                                            </div>
                                            <div className="second_icon" style={{width:"15%"}}>
                                            <img src={second_img_url} alt="" className="icon_img_bottom" />
                                            </div>
                                            <div className="second_icon_with_text" style={{width:"15%"}}>
                                            <span>+{value.Icons.length - 2}</span>
                                            </div>
                                            </>
                                        }
                                        {value.Icons.length > 0  &&  value.Icons.length == 2 &&
                                            <>
                                            <div className="first_icon" style={{width:"20%",borderLeft:"1px solid #ffffff"}}>
                                            <img src={first_img_url} alt="" className="icon_img_bottom" />
                                            </div>
                                            <div className="second_icon" style={{width:"20%"}}>
                                            <img src={second_img_url} alt="" className="icon_img_bottom" />
                                            </div>
                                            </>
                                        }
                                        {value.Icons.length == 1 &&
                                            <>
                                            <div className="first_icon" style={{width:"20%",borderLeft:"1px solid #ffffff"}}>
                                            <img src={first_img_url} alt="" className="icon_img_bottom" />
                                            </div>
                                            </>
                                        }
                                        </>
                                    }
                                    </div>
                                    <div className="certificationinfo" style={{display:value.ProductID == defaultCurrenthover ? "block" : "none"}} onMouseLeave={() => checkmouseleve()}>
                                        <div style={{borderBottom:"1px solid #cccccc",paddingBottom:"1px"}}>
                                            <div style={{display:"flex",height:"9px"}}>
                                                <div style={{width: "95%",display:"flex"}}>
                                                    <img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/green_leaf.png" className="green_lab" alt="green leaf" />
                                                    <p className="earthly_btn_header">Earthly Rating - <span className="ratings_number">{value.rating}</span></p>
                                                </div>
                                                <div>
                                                    <span>
                                                        <a href="https://joinearthly.com/method" target="_blank">
                                                            <img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/question-circle.png" style={{height:"16px"}} />
                                                        </a>
                                                     </span>
                                                </div>
                                            </div>
                                            <span style={{fontSize:"12px",color: "#828282"}}>Earthly is not affiliated with this product or brand</span>
                                        </div>
                                        <div className="all_icon">
                                            <div className="icon_list_with_info" style={{borderBottom:"1px solid #cccccc"}}>
                                                <ul style={{margin:"10px 5px"}}>
                                                    {value.Icons.length > 0 &&
                                                            value.Icons.map((icondata,valueindex) =>{

                                                                let icon_path = icondata.icon;
                                                                let icon_color = icondata.color.toLowerCase();
                                                                let final_icon_path = icon_path.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color).replace('.png', ".svg");

                                                                return (<li style={{listStyle:"none",display:"flex",height:"30px",lineHeight:"30px",marginBottom:"0px"}}>
                                                                    <div style={{width:"96%"}}>
                                                                        <img src = {final_icon_path} style={{width:"20px",marginBottom:"0px"}} />
                                                                        <span style={{paddingLeft:"8px",fontSize:"15px",color:"#323232",fontWeight:"500"}}>{icondata.text}</span>
                                                                    </div>
                                                                    <div className="earthly_tooltip">
                                                                        <span style={{float: "right"}} data-tooltip={icondata.description} data-toggle="tooltip" data-tooltip-position="bottom">
                                                                            <img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/info.svg" style={{height:"20px"}} />
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                )
                                                            })
                                                        }
                                                </ul>
                                            </div>
                                            <div>
                                                <div className="certification_section">
                                                    <p className="certification_text">Certifications</p>
                                                    <div>
                                                        <ul style={{marginTop:"0px",display:"flex",marginLeft:"0px"}}>
                                                        {value.Certifications.length > 0 &&
                                                                value.Certifications.map((cerfivalue,certiindex) => {
                                                                        return (
                                                                            <li style={{paddingRight:"13px",listStyle:"none",marginBottom:"0px"}}><a href={cerfivalue.link} target="_blank" title={cerfivalue.title}><img src={cerfivalue.certifications} height="20px" style={{marginBottom:"0px"}}/></a></li>
                                                                        )

                                                                })

                                                         }

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="certification_section">
                                        </div>
                                        <div className="related_product_section">
                                        </div>
                                    </div>
                                    </div>
                                    <div className="product_align">
                                    <p className="price">{value.Price}</p>
                                    </div>
                                    </div>
                                )
                            })

                        }
                            </div>
                        </div>

                    )
            }) }
        <div className="grid-container">
        {defaultdisplay !== "default" &&
            productdata !== "" && productdata.map((value,index) => {
            let background_color = "#15B770";
            let fix_url = "https://earthlydev.s3-us-west-2.amazonaws.com/icons";
            let first_img_url;
            let second_img_url;
            if(value.Icons.length >= 2){
                let first_icon = value.Icons[0].icon;
                let icon_color = value.Icons[0].color.toLowerCase();
                first_img_url = first_icon.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color + '/dots').replace('.png', ".svg");

                let second_icon = value.Icons[1].icon;
                let icon_color_second = value.Icons[1].color.toLowerCase();
                second_img_url = second_icon.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color_second + '/dots').replace('.png', ".svg");

            }else if(value.Icons.length == 1){
                let first_icon = value.Icons[0].icon;
                let icon_color = value.Icons[0].color.toLowerCase();
                first_img_url = first_icon.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color + '/dots').replace('.png', ".svg");
            }
            return(
                <div className="grid-item" key={index}>
                <img src={value.ImageURL !== "" ? value.ImageURL:"https://earthlydev.s3.us-west-2.amazonaws.com/ext_assets/images/placeholder.svg" } alt="product_one" style={{height:"320px",marginBottom:"8px"}}/>
                <div className="name_text">
                <p className="product_name">{value.Amazon_Product_Full_Name}</p>
                </div>
                <div className="product_align">

                <div className="row earthlybtn" style={{backgroundColor:value.rating <= 2 ? "#EB5757" : "#15B770"}} value={index} onMouseEnter={()=>checkforelement(index)}>
                {value.rating !== undefined &&
                    <>
                    <div className="first_leaf_rateing" style={{width:"45%"}}>
                    <div className="leaf_star_first">
                    <div className="star-rating harambe">
                    <span style={{width:value.rating*20+"%"}}></span>
                    </div>
                    </div>
                    </div>
                    {value.Icons.length > 0  &&  value.Icons.length >= 3 &&
                        <>
                        <div className="first_icon" style={{width:"20%",borderLeft:"1px solid #ffffff"}}>
                        <img src={first_img_url} alt="" className="icon_img_bottom" />
                        </div>
                        <div className="second_icon" style={{width:"15%"}}>
                        <img src={second_img_url} alt="" className="icon_img_bottom" />
                        </div>
                        <div className="second_icon_with_text" style={{width:"15%"}}>
                        <span>+{value.Icons.length - 2}</span>
                        </div>
                        </>
                    }
                    {value.Icons.length > 0  &&  value.Icons.length == 2 &&
                        <>
                        <div className="first_icon" style={{width:"20%",borderLeft:"1px solid #ffffff"}}>
                        <img src={first_img_url} alt="" className="icon_img_bottom" />
                        </div>
                        <div className="second_icon" style={{width:"20%"}}>
                        <img src={second_img_url} alt="" className="icon_img_bottom" />
                        </div>
                        </>
                    }
                    {value.Icons.length == 1 &&
                        <>
                        <div className="first_icon" style={{width:"20%",borderLeft:"1px solid #ffffff"}}>
                        <img src={first_img_url} alt="" className="icon_img_bottom" />
                        </div>
                        </>
                    }
                    </>
                }
                </div>
                <div className="certificationinfo" style={{display:index == currenthover ? "block" : "none"}} onMouseLeave={() => checkmouseleve()}>
                    <div style={{borderBottom:"1px solid #cccccc",paddingBottom:"1px"}}>
                        <div style={{display:"flex",height:"9px"}}>
                            <div style={{width: "95%",display:"flex"}}>
                                <img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/green_leaf.png" className="green_lab" alt="green leaf" />
                                <p className="earthly_btn_header">Earthly Rating - <span className="ratings_number">{value.rating}</span></p>
                            </div>
                            <div>
                                <span>
                                    <a href="https://joinearthly.com/method" target="_blank">
                                        <img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/question-circle.png" style={{height:"16px"}} />
                                    </a>
                                 </span>
                            </div>
                        </div>
                        <span style={{fontSize:"12px",color: "#828282"}}>Earthly is not affiliated with this product or brand</span>
                    </div>
                    <div className="all_icon">
                        <div className="icon_list_with_info" style={{borderBottom:"1px solid #cccccc"}}>
                            <ul style={{margin:"10px 5px"}}>
                                {value.Icons.length > 0 &&
                                        value.Icons.map((icondata,valueindex) =>{

                                            let icon_path = icondata.icon;
                                            let icon_color = icondata.color.toLowerCase();
                                            let final_icon_path = icon_path.replace('https://earthlydev.s3-us-west-2.amazonaws.com/icons', fix_url + "/" + icon_color).replace('.png', ".svg");

                                            return (<li style={{listStyle:"none",display:"flex",height:"30px",lineHeight:"30px",marginBottom:"0px"}}>
                                                <div style={{width:"96%"}}>
                                                    <img src = {final_icon_path} style={{width:"20px",marginBottom:"0px"}} />
                                                    <span style={{paddingLeft:"8px",fontSize:"15px",color:"#323232",fontWeight:"500"}}>{icondata.text}</span>
                                                </div>
                                                <div className="earthly_tooltip">
                                                    <span style={{float: "right"}} data-tooltip={icondata.description} data-toggle="tooltip" data-tooltip-position="bottom">
                                                        <img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/info.svg" style={{height:"20px"}} />
                                                    </span>
                                                </div>
                                            </li>
                                            )
                                        })
                                    }
                            </ul>
                        </div>

                            <div className="certification_section">
                                <p className="certification_text">Certifications</p>
                                <div>
                                    <ul style={{marginTop:"0px",display:"flex",marginLeft:"0px",marginBottom:"5px"}}>
                                    {value.Certifications !== "" &&
                                            value.Certifications.map((cerfivalue,certiindex) => {
                                                    return (
                                                        <li style={{paddingRight:"13px",listStyle:"none",marginBottom:"0px"}}><a href={cerfivalue.link} target="_blank" title={cerfivalue.title}><img src={cerfivalue.certifications} style={{marginBottom:"0px",height:"20px"}}/></a></li>
                                                    )

                                            })

                                     }

                                    </ul>
                                </div>
                            </div>
                            {
                                topprodcut !== "" &&

                                <div className="related_product_section">
                                <p className="top_rated_text" style={{paddingTop:"5px"}}>Top rated product in this category</p>
                                <div>
                                        <a href="#" target="_blank" value={topprodcut.ProductID} className="product_link">
                                            <div className="rel_product" style={{marginBottom:"30px"}}>
                                              <div className="related_product">
                                                 <div className="left_related">
                                                    <img src={topprodcut.ImageURL !== "" ? topprodcut.ImageURL :"https://earthlydev.s3.us-west-2.amazonaws.com/ext_assets/images/placeholder.svg"} alt="product_img"/>
                                                 </div>
                                                 <div className="right_related">
                                                    <p className="related_brand_category">Brand: {topprodcut.Brand}</p>
                                                    <p className="related_brand_name">{topprodcut.Amazon_Product_Full_Name}</p>
                                                    <div className="rel_product_star_rating_section">
                                                       <div className="rel_product_star harambe"><span style={{width:topprodcut.Star_rating*20 + "%"}}></span></div>
                                                       <div className="user_review"><label style={{padding:"0px"}}>{topprodcut.Reviews}</label></div>
                                                    </div>
                                                    <div className="related_product_green_btn" style={{backgroundColor:"#15B770"}}>
                                                       <div className="ear_rel_product_star_rating_section">
                                                          <div className="ear_rel_product_star harambe"><span style={{width:topprodcut.rating*20 + "%"}}></span></div>
                                                          <div className="icon_list">
                                                             <ul className="related_icon_list">
                                                                {geticonsdata(topprodcut)}

                                                             </ul>
                                                          </div>
                                                       </div>
                                                    </div>
                                                        {
                                                            getprice(topprodcut)
                                                        }
                                                    <div>
                                                       <div className="prime_img"><span><img src="https://earthlydev.s3-us-west-2.amazonaws.com/ext_assets/images/prime.svg" height="14px" alt="prime"/></span><span className="prime_span">FREE One-Day</span></div>
                                                    </div>
                                                 </div>
                                              </div>
                                            </div>
                                        </a>
                                </div>
                            </div>
                            }
                    </div>

                </div>
                </div>
                <div className="product_align">
                <p className="price">{value.Price}</p>
                </div>
                </div>
            )
        })
    }
    </div>
    </div>
    </div>
    </Layout>
)
}

export default Catalog
